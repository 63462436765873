import React from 'react'
import GoogleMapReact from 'google-map-react';

const HighLight = ({ children }) => (
  <div>{children}</div>
)

// mandatory to fix style w & h
const AreaMap = ({ coordinates, mapKey, children }) => (
  <div style={{ height: '100%', width: '100%' }}>
    <GoogleMapReact bootstrapURLKeys={{ key: mapKey, version: "weekly", libraries: ['places'] }} defaultCenter={coordinates} defaultZoom={16} >
      <HighLight {...coordinates}>{children}</HighLight>
    </GoogleMapReact>
  </div>
);

export default AreaMap;