import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import { Grid, Divider, Icon } from 'semantic-ui-react';
import { useStaticQuery, graphql } from "gatsby"
import AreaMap from '../components/areamap'
import ContactForm from '../components/contactform';
import Img from "gatsby-image"
import logosquare from '../assets/images/logos/logosquare.svg'
import useViewPort from '../utils/useViewPort';


const markstyle = {
  backgroundColor: 'rgba(255,255,255,0.5)',
  color: 'black',
  height: 55,
  border: '1px solid black',
  width: 75,
  borderRadius: 5,
  textAlign: 'center',
  margin: -24,
  padding: 3,
}

const Address = ({ addressLine1, addressLine2, postalCode, phoneNumber, faxNumber, email, domain }) => (
  <address>
    <h3><Icon name="point"/>Our Address</h3>
    <p><Icon name="home"/>{addressLine1}</p>
    <p><Icon />{addressLine2}</p>
    <p><Icon />Singapore {postalCode}</p>
    <p><Icon name="phone"/><a href={`tel:${phoneNumber}`}>{phoneNumber}</a></p>
    <p><Icon name="fax" />{faxNumber}</p>
    <p><Icon name="mail" /><a href={`mailto:${email}`}>{email}</a></p>
    <p><Icon name="world" /><a href={`https://${domain}`}>{domain}</a></p>
  </address>
)

const ContactPage = (params) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          company
          addressLine1
          addressLine2
          postalCode
          phoneNumber
          faxNumber
          email
          uen
          domain
          webEmail
          coordinates {
            lat
            lng
          }
          keys {
            formKey
            mapKey
          }
        }
      }
    }
  `);
  const { isMobile } = useViewPort();

  const { keys, coordinates, ...rest } = data?.site?.siteMetadata;
  return (
    <Layout {...params}>
      <div className="contact page">
        <SEO title="Contact" />
        <Banner>Contact us</Banner>

        <section className="content description" style={{ marginTop: 20 }}>
          <p>As a company dedicated to our clients' satisfaction, we are pleased to address any enquiries that they may have. Please, do not hesitate to call us! </p>
          <Divider/>
        </section>

        <section className="content contact">
          {isMobile ? (
            <div>
              <Address {...rest}/>
              <Divider />
              <ContactForm {...keys} load={false}/>
            </div>
          ) : (
            <Grid>
              <Grid.Column width={5}>
                <Address {...rest}/>
              </Grid.Column>
              <Grid.Column width={10}>
                <ContactForm {...keys} load={false}/>
              </Grid.Column>
            </Grid>
          )}
          
          <div>
          </div>
        </section>

        <section className="content locate">
          <h2>Locate us</h2>
          <div style={{ margin: 'auto', marginTop: `1.4rem`, width: '100%', height: '50vw' }}>
            <AreaMap coordinates={coordinates} {...keys}>
              <div style={markstyle}><Img alt="Jinmac Pte Ltd" imgStyle={{ position: 'relative' }} fluid={{src: logosquare }}/></div>
            </AreaMap>
          </div>
        </section>

      </div>

    </Layout>
  );
};

export default ContactPage;